.MuiFormControl-root {
  padding-bottom: 7px;
}

.MuiFormControl-root.MuiTextField-root {
  padding-bottom: 0px;
}

.MuiOutlinedInput-root {
  height: 43px;
}

.MuiInputLabel-outlined {
  transform: translate(14px, 15px) scale(1);
}

.MuiSelect-outlined.MuiSelect-outlined {
  padding: 11px;
  padding-right: 32px;
}

.MuiOutlinedInput-multiline {
  height: 113px !important;
}

.MuiFormControl-root.MuiTextField-root {
  padding-bottom: 0px;
}

.MuiExpansionPanel-root:before {
  background-color: transparent;
}

.MuiExpansionPanelSummary-root.Mui-expanded {
  min-height: 48px;
}

.MuiPaper-root .MuiDrawer-paper,
:after {
  opacity: 0 !important;
}

textarea:focus {
  outline-color: #5bcaff;
}
